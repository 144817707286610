import renderSignup from './signup.jsx'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'

const env = window.Confetti.env
if (env === 'development' || env === 'production') {
  Sentry.init({
    dsn: 'https://9f6a46291dc94c75ab74de73fd15ec16@o17965.ingest.sentry.io/34838',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.03,
    environment: env,
  })
}

const container = document.createElement('div')
document.body.appendChild(container)


renderSignup(container, window.Confetti)
